<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <h2>
            {{ $t("LABELS.holidaysFor") }}:
            <template v-if="operator">{{
              `${operator.firstName} ${operator.lastName}`
            }}</template>
            <template v-else>Loading...</template>
          </h2>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="10" class="d-flex align-items-center">
          <v-btn
            @click="handleAddClick"
            class="btn btn-success btn-elevate kt-login__btn-primary text-white"
          >
            {{ $t("BUTTON.ADD_HOLIDAY") }}
          </v-btn>
        </v-col>
        <v-col class="text-right" cols="2">
          <v-select
            v-model="headers.rows"
            :items="tableOptions.rowsPerPage"
            :label="$t('A.PER_PAGE')"
            chips
            attach
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="mb-6 t-header">
        <v-col
          v-for="column of headers.columns"
          :key="column.name"
          :cols="column.cols"
        >
          <span @click="changeOrder(column.name)">
            {{
              column.name == "type"
                ? $t("LABELS.type.name")
                : $t("LABELS." + column.name)
            }}
            <span v-if="column.name == headers.order">{{
              orderIcon
            }}</span></span
          >
        </v-col>

        <v-col cols="1">
          {{ $t("LABELS.operations") }}
        </v-col>
      </v-row>

      <div v-show="!isLoadingOperators">
        <v-row class="mb-6 t-row" v-for="item of items" :key="item.id">
          <v-col
            class="t-col"
            v-for="col of headers.columns"
            :key="col.name"
            :cols="col.cols"
          >
            <template v-if="col.name === 'from'">
              {{ item.from | formatDate }}
            </template>
            <template v-else-if="col.name === 'to'">
              {{ item.to | formatDate }}
            </template>
            <template v-else-if="col.name === 'type'">
              {{ item.type.name }}
            </template>
            <template v-else>
              {{ item[col.name] }}
            </template>
          </v-col>
          <v-col
            cols="1"
            class="t-col d-flex justify-content-between flex-wrap"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2 mt-2"
                  fab
                  dark
                  x-small
                  color="#3dbdad"
                  v-bind="attrs"
                  v-on="on"
                  @click.native="handleEditClick(item)"
                >
                  <v-icon>
                    fas fa-edit
                  </v-icon>
                </v-btn>
              </template>
              <span>Промени</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2 mt-2"
                  fab
                  dark
                  x-small
                  color="#f5222d"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteData(item)"
                >
                  <v-icon>
                    fas fa-trash-alt
                  </v-icon>
                </v-btn>
              </template>
              <span>Изтрий</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            {{ totalItems }} {{ $t("A.RESULTS") }}
          </v-col>
        </v-row>
      </div>

      <v-progress-linear
        class="mt-5"
        color="#25bdad"
        buffer-value="0"
        stream
        reverse
        :active="isLoadingOperators"
      ></v-progress-linear>

      <v-row v-if="totalPages > 0">
        <v-col class="text-center paginator">
          <v-btn
            class="m-1"
            v-for="page in visiblePages"
            :key="page.name"
            :disabled="page.isDisabled"
            depressed
            small
            @click="headers.page = page.name"
          >
            {{ page.name }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <HolidayFormDialog
      v-if="formDialog && operatorHolidayTypes.length"
      v-model="formDialog"
      :operator="form.operatorId"
      :operatorHolidayTypes="operatorHolidayTypes"
      :item="editedItem"
      @input="
        formDialog = $event;
        editedItem = null;
      "
      @submit="handleFormSubmit"
    />
  </div>
</template>

<script>
import { GET_DATA } from "@/store/formselect.module";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import HolidayFormDialog from "./components/HolidayFormDialog.vue";
import {
  GET_OPERATOR,
  GET_OPERATOR_HOLIDAYS,
  SAVE_OPERATOR_HOLIDAY,
  UPDATE_OPERATOR_HOLIDAY,
  DELETE_OPERATOR_HOLIDAY
} from "@/store/operators.module";

import { mapGetters } from "vuex";

export default {
  components: {
    HolidayFormDialog
  },

  data() {
    return {
      operator: null,
      formDialog: false,
      operatorHolidayTypes: [],
      editedItem: null,
      form: {
        operatorId: parseInt(this.$route.params.id),
        from: null,
        to: null,
        typeId: null
      },
      action: "operatorHolidays",
      tableOptions: {
        showSearch: false,
        draggableCols: true,
        selectable: true,
        rowsPerPage: [2, 10, 20, 50],
        maxVisibleButtons: 5
      },
      items: [],
      totalItems: 0,
      totalPages: 0,
      headers: {
        columns: [
          {
            name: "id",
            cols: "1"
          },
          {
            name: "from"
          },
          {
            name: "to"
          },
          {
            name: "type"
          }
        ],
        rows: 10,
        page: 1,
        order: "id",
        orderType: "desc"
      }
    };
  },
  computed: {
    ...mapGetters(["isLoadingOperators"]),
    orderIcon: function() {
      let vm = this;

      return vm.headers.orderType == "asc" ? "▲" : "▼";
    },

    visiblePages() {
      const range = [];
      let vm = this;

      let start, end;

      if (vm.headers.page < Math.ceil(vm.tableOptions.maxVisibleButtons / 2)) {
        start = 1;

        if (vm.tableOptions.maxVisibleButtons > vm.totalPages) {
          end = vm.totalPages;
        } else {
          end = vm.tableOptions.maxVisibleButtons;
        }
      } else if (
        vm.headers.page + Math.ceil(vm.tableOptions.maxVisibleButtons / 2) >
        vm.totalPages
      ) {
        start = vm.totalPages - vm.tableOptions.maxVisibleButtons + 1;
        end = vm.totalPages;
      } else {
        start =
          vm.headers.page - Math.floor(vm.tableOptions.maxVisibleButtons / 2);
        end =
          vm.headers.page + Math.floor(vm.tableOptions.maxVisibleButtons / 2);
      }

      for (let i = start; i <= end; i += 1) {
        range.push({
          name: i,
          isDisabled: i === vm.headers.page
        });
      }

      return range;
    }
  },
  watch: {
    "headers.rows": function(new_value) {
      let vm = this;
      new_value = parseInt(new_value);
      window.localStorage.setItem(vm.action + "_rows", new_value);

      vm.headers.page = 1;
      vm.fetchData();
    },
    "headers.page": function() {
      let vm = this;
      vm.fetchData();
    }
  },
  mounted() {
    let vm = this;

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: vm.$t("PAGES.OPERATORS_HOLIDAYS.breadcrumb") }
    ]);

    vm.fetchOperator();
    vm.fetchData();
    vm.fetchHolidayTypes();
  },
  methods: {
    getOptions: function() {
      let vm = this;

      vm.headers.order =
        window.localStorage.getItem(vm.action + "_order") ||
        vm.headers.order ||
        "id";
      vm.headers.orderType =
        window.localStorage.getItem(vm.action + "_orderType") ||
        vm.headers.orderType ||
        "asc";
      vm.headers.rows = parseInt(
        window.localStorage.getItem(vm.action + "_rows") || 10
      );
    },
    changeOrder: function(key) {
      let vm = this;

      if (key === "type") {
        return;
      }

      if (vm.headers.order == key) {
        let oType = vm.headers.orderType == "asc" ? "desc" : "asc";

        vm.headers.orderType = oType;
      } else {
        vm.headers.order = key;
      }

      vm.headers.page = 1;
      window.localStorage.setItem(vm.action + "_order", vm.headers.order);
      window.localStorage.setItem(
        vm.action + "_orderType",
        vm.headers.orderType
      );

      vm.fetchData();
    },

    fetchOperator() {
      let vm = this;

      let id = vm.$route.params.id;

      vm.$store
        .dispatch(GET_OPERATOR, id)
        .then(data => {
          vm.$nextTick(function() {
            vm.operator = data.data;
          });
        })
        .catch(() => {
          vm.$notification["error"]({
            message: vm.$t("ERRORS.DEFAULT.MESSAGE"),
            description: vm.$t("ERRORS.DEFAULT.DESCRIPTION")
          });
        });
    },

    fetchData() {
      let vm = this;

      vm.getOptions();

      vm.$store
        .dispatch(GET_OPERATOR_HOLIDAYS, {
          id: vm.$route.params.id,
          payload: vm.headers
        })
        .then(data => {
          vm.$nextTick(function() {
            vm.items = data.data.data;
            vm.totalItems = data.data.recordsTotal;
            vm.totalPages = data.data.totalPages;
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "operatorsList" });
          }
        });
    },
    deleteData(item) {
      let vm = this;

      vm.$confirm({
        title: vm.$t("CONFIRM.DELETE.MESSAGE"),
        content: vm.$t("CONFIRM.DELETE.DESCRIPTION"),
        icon: "warning",
        okText: vm.$t("BUTTON.CONFIRM"),
        cancelText: vm.$t("BUTTON.CANCEL"),
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        onOk() {
          vm.execDelete(item);
        }
      });
    },
    execDelete(item) {
      let vm = this;

      vm.$store
        .dispatch(DELETE_OPERATOR_HOLIDAY, item.id)
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.fetchData();
        })
        .catch(() => {
          vm.$notification["error"]({
            message: vm.$t("ERRORS.DEFAULT.MESSAGE"),
            description: vm.$t("ERRORS.DEFAULT.DESCRIPTION")
          });
        });
    },

    fetchHolidayTypes: function() {
      let vm = this;

      let payload = {
        operatorHolidayTypes: {
          fields: ["id", "name"],
          search: ""
        }
      };

      this.$store
        .dispatch(GET_DATA, payload)
        .then(data => {
          vm.operatorHolidayTypes = data.data.operatorHolidayTypes;
        })
        .catch(() => {
          vm.$notification["error"]({
            message: vm.$t("ERRORS.DEFAULT.MESSAGE"),
            description: vm.$t("ERRORS.DEFAULT.DESCRIPTION")
          });
        });
    },

    handleAddClick() {
      this.formDialog = true;
    },

    handleEditClick(item) {
      this.editedItem = item;
      this.formDialog = true;
    },

    handleFormSubmit(form) {
      let vm = this;

      let action;

      if (this.editedItem) {
        action = UPDATE_OPERATOR_HOLIDAY;
        form.id = this.editedItem.id;
      } else {
        action = SAVE_OPERATOR_HOLIDAY;
      }

      this.$store
        .dispatch(action, form)
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.fetchData();
        })
        .catch(() => {
          vm.$notification["error"]({
            message: vm.$t("ERRORS.DEFAULT.MESSAGE"),
            description: vm.$t("ERRORS.DEFAULT.DESCRIPTION")
          });
        })
        .finally(() => {
          this.editedItem = null;
        });
    }
  },

  beforeDestroy: function() {
    this.items = null;
    delete this.items;
  }
};
</script>
