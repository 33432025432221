<template>
  <v-dialog v-bind="$attrs" @input="handleDialogInput" width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{
          !item
            ? $t("PAGES.OPERATORS_HOLIDAYS.formAddHolidayHeading")
            : $t("PAGES.OPERATORS_HOLIDAYS.formEditHolidayHeading")
        }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            {{ $t("LABELS.from") }}:
            <datetime
              zone="local"
              value-zone="local"
              type="date"
              v-model.trim="form.from"
              input-class="form-control"
              @input="validateToDate"
            ></datetime>
            <div v-if="$v.form.$error">
              <div class="text-danger" v-if="!$v.form.from.required">
                {{ $t("VALIDATIONS.REQUIRED") }}
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            {{ $t("LABELS.to") }}:
            <datetime
              zone="local"
              value-zone="local"
              type="date"
              v-model="form.to"
              input-class="form-control"
              @input="validateToDate"
            ></datetime>
            <div v-if="$v.form.$error">
              <div class="text-danger" v-if="!$v.form.to.required">
                {{ $t("VALIDATIONS.REQUIRED") }}
              </div>
            </div>
            <div class="text-danger" v-if="toDateError">
              {{ $t("VALIDATIONS.TO_DATE_AFTER") }}
            </div>
          </v-col>
        </v-row>
        <v-col class="col-12">
          {{ $t("LABELS.type.name") }}
          <select v-model="form.typeId" type="text" class="form-control">
            <option
              v-for="type in operatorHolidayTypes"
              :key="type.id"
              :value="type.id"
            >
              {{ type.name }}</option
            >
          </select>
        </v-col>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleSubmit">
          {{ !item ? $t("BUTTON.ADD") : $t("BUTTON.SAVE") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    operator: {
      type: Number,
      required: true
    },

    operatorHolidayTypes: {
      type: Array,
      required: true
    },

    item: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      form: {
        operatorId: this.operator,
        from: null,
        to: null,
        typeId: this.operatorHolidayTypes[0].id
      },
      toDateError: false
    };
  },

  validations: {
    form: {
      from: {
        required
      },
      to: {
        required
      }
    }
  },

  mounted() {
    this.initForm();
  },

  methods: {
    initForm() {
      if (this.item) {
        this.form.from = this.item.from;
        this.form.to = this.item.to;
        this.form.typeId = this.item.type.id;
      }
    },

    handleDialogInput(input) {
      this.resetForm();

      this.$emit("input", input);
    },

    resetForm() {
      this.form.from = null;
      this.form.to = null;
      this.form.typeId = this.operatorHolidayTypes[0].id;

      this.$v.$reset();
      this.toDateError = false;
    },

    handleSubmit() {
      this.$v.$touch();

      if (!this.$v.form.$error && !this.toDateError) {
        this.form.from = moment(this.form.from).format("YYYY-MM-DD");
        this.form.to = moment(this.form.to).format("YYYY-MM-DD");

        this.$emit("submit", this.form);
        this.$emit("input", false);
        this.resetForm();
      }
    },

    validateToDate() {
      this.toDateError = false;

      if (this.form.from && this.form.to) {
        this.toDateError = moment(this.form.to).isBefore(
          moment(this.form.from)
        );
      }
    }
  }
};
</script>
